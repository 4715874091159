/**
 * Current status of the offer.
 * Each offer has a life-cycle what is controlled by the status of the offer.
 */
/* eslint-disable-next-line no-shadow */
export enum OfferStatus {
  /**
   * The offer is not yet visible by the operators. The creator can edit the
   * offer in this status.
   */
  Draft = 'draft',

  /**
   * The offer has been sent for review to Alteo. In this state the offer is
   * not editable by the user, if any edit is made to the offer it reverts back
   * to draft status.
   */
  PriceRequest = 'price-request',

  /**
   * Alteo provided a price quote for the offer. In this state the offer is
   * not editable by the user, if any edit is made to the offer it reverts back
   * to draft status.
   */
  PriceReview = 'price-review',

  /**
   * The offer has been accepted. In this status no-one can edit the offer
   * anymore and it can be used to create a contract group.
   */
  Accepted = 'accepted',
}
