import { addHours, addMinutes, startOfHour } from 'date-fns';

/**
 * Returns a date rounded to the next quarter hour.
 *
 * @example
 * 16:06 -> 16:15
 * 12:31 -> 12:45
 * 20:55 -> 21:00
 *
 * @param date - Input date.
 */
export function nextQuarterHour(date: Date): Date {
  if (date.getMinutes() > 45) {
    return addMinutes(startOfHour(addHours(date, 1)), 15);
  }

  const newDate = new Date(date);
  newDate.setMinutes((Math.ceil(date.getMinutes() / 15) + 1) * 15);

  return newDate;
}
