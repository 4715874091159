export const environment = {
  production: true,
  shouldReportErrors: true,
  environment: 'production',
  tokenExpirationCheckInterval: 5 * 60 * 1000,
  tokenExpirationThreshold: 24 * 60 * 60 * 1000,
  baseUrl: 'https://app-sinergy-prod-01.azurewebsites.net',
  defaultOffset: 0,
  defaultLimit: 30,
  reCaptchaSiteKey: '6Lcr6vQZAAAAAKlS3dPgNqJ9nZeENDMIaRfuFsa7',
};
