<header class="header mat-elevation-z6">
  <nav class="vertical-nav">
    <mat-icon class="drawer-toggle" (click)="drawer.toggle()">menu</mat-icon>
    <a class="logo-container" routerLink="/">
      <img src="/assets/images/alteo_logo_white.svg" alt="Alteo Logo" />
    </a>
    <div class="navigation-items">
      <ng-container *ngFor="let item of menuOptions">
        <a
          class="nav-link"
          mat-button
          *ngIf="rolesService.checkRole(item.requiredRole) | async"
          [routerLink]="item.location"
          routerLinkActive="nav-link--activated"
        >
          {{ item.translationKey | translate }}
        </a>
      </ng-container>
    </div>
    <div class="spacer"></div>
    <mat-icon *ngIf="(rolesService.isOperator() | async)" class="operator-indicator" matTooltip="Operator">
      supervisor_account</mat-icon
    >
    <div class="navigation-items">
      <a class="nav-link" mat-button routerLink="/profile" routerLinkActive="nav-link--activated">{{ 'core.menu.profile' | translate }}</a>
      <button mat-button *ngIf="authService.authenticated" (click)="logout()">
        {{ 'core.menu.logout' | translate }}
      </button>
    </div>
  </nav>
</header>
<!-- We need to use sidenav because mat-drawer cannot be fixed to viewport. -->
<mat-sidenav-container>
  <!-- The fixedTopGap property should equal to header size.  -->
  <mat-sidenav #drawer mode="over" [fixedInViewport]="true" fixedTopGap="52" fixedBottomGap="0">
    <nav class="horizontal-nav">
      <ng-container *ngFor="let item of menuOptions">
        <a
          class="nav-link"
          mat-button
          *ngIf="rolesService.checkRole(item.requiredRole) | async"
          (click)="drawer.close()"
          [routerLink]="item.location"
          routerLinkActive="nav-link--activated"
        >
          {{ item.translationKey | translate }}
        </a>
      </ng-container>
      <div class="spacer"></div>
      <a class="nav-link" mat-button routerLink="/profile" (click)="drawer.close()" routerLinkActive="nav-link--activated">{{ 'core.menu.profile' | translate }}</a>
      <button mat-button (click)="logout()">{{ 'core.menu.logout' | translate }}</button>
    </nav>
  </mat-sidenav>

  <div class="mat-typography content-container">
    <router-outlet></router-outlet>
  </div>
</mat-sidenav-container>
