/**
 * Checks if the given {@link HTMLElement} is overflowing or not.
 *
 * @param htmlElement {@link HTMLElement} to check.
 *
 * @returns `true` if the given {@link HTMLElement} is overflowing, otherwise `false`.
 */
export function isOverflowing(htmlElement: HTMLElement): boolean {
  return (
    htmlElement.style.overflow === 'visible' ||
    htmlElement.clientWidth < htmlElement.scrollWidth ||
    htmlElement.clientHeight < htmlElement.scrollHeight
  );
}
