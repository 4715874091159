import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import * as sentry from '@sentry/browser';

sentry.init({
  enabled: environment.shouldReportErrors,
  environment: environment.environment,
  dsn: 'https://7d28509fcc9a46a0978c422d25ee8c0a@o185250.ingest.sentry.io/5520983',
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  public handleError(error: unknown) {
    if (environment.shouldReportErrors) {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      sentry.captureException((error as any).originalError || error);
    }

    /**
     * Not handled network errors will end up here with the HttpErrorResponse
     * object. We need to transform that into a normal error when reporting to Sentry.
     */
    if (error instanceof HttpErrorResponse) {
      sentry.captureMessage(`Error response is unhandled for ${error.url}.`, {
        level: 'error',
        extra: {
          errorResponsePayload: error.error,
          message: error.message,
        },
      });
    }

    console.error('An unhandled error happened in the application!');
    console.error(error);

    // TODO: We should throw a popup here explaining that an unhandled error happened.
  }
}
