import { Injectable } from '@angular/core';
import * as sentry from '@sentry/browser';
import { NetworkService } from '@spreadmonitor/network';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, finalize, map, pluck, tap } from 'rxjs/operators';
import { ApiResponse } from '../../shared/interfaces';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  private refreshing = false;
  private rolesStream: BehaviorSubject<{ active: boolean; role: string }[]> = new BehaviorSubject([]);

  constructor(
    private readonly network: NetworkService,
    private readonly authService: AuthService
  ) {
    if (this.authService.authenticated) {
      this.refresh();
    }
  }

  public refresh(): void {
    if (this.refreshing) {
      return;
    }

    this.refreshing = true;

    this.network
      .get<ApiResponse<{ role: string; active: boolean }[]>>('/v1/users/me/roles')
      .pipe(
        pluck('payload'),
        tap(roles => {
          this.refreshing = false;
          this.rolesStream.next(roles);
        }),
        finalize(() => (this.refreshing = false))
      )
      .subscribe();
  }

  public checkRole(role: string): Observable<boolean> {
    return this.rolesStream.pipe(
      filter(() => !this.refreshing),
      tap(roles => {
        if (roles.length && !roles.find(r => r.role === role)) {
          sentry.captureMessage(`Unknown role ${role}.`, 'error');
        }
      }),
      map(roles => this.authService.authenticated && !!roles.find(r => r.active && r.role === role))
    );
  }

  public isOperator(): Observable<boolean> {
    return this.checkRole('management::operator');
  }

  /**
   * Checks if the user is an operator or not based on currently available role information.
   * ??? IMPORTANT: Use this function with care, it can be called only if we are sure
   * ??? role information already loaded and up-to-date.
   */
  public isOperatorInstant(): boolean {
    return !!this.rolesStream
      .getValue()
      .find(wrapper => wrapper.role === 'management::operator' && wrapper.active === true);
  }
}
