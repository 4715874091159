import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NetworkAuthService, NetworkModule } from '@spreadmonitor/network';
import { environment } from '../environments/environment';
import { AuthService } from './auth/services';
import { SentryErrorHandler } from './error-handler.class';
import { RootRoutingModule } from './root-routing.module';
import { RootViewComponent } from './root.view';

/**
 * Import locale data for Hungary. This is used by various pipes, eg:
 * DatePipe, DecimalPipe, etc.
 */
import '@angular/common/locales/global/hu';
import { AcceptLanguageInterceptor } from './accept-language.interceptor';
import { RefreshRolesInterceptor } from './refresh-roles.interceptor';

// AoT requires an exported function for factories
export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [RootViewComponent],
  imports: [
    BrowserModule,
    RootRoutingModule,
    BrowserAnimationsModule,
    NetworkModule.forRoot({ authType: 'bearer', baseUrl: environment.baseUrl }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'hu',
    }),
  ],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: NetworkAuthService, useExisting: AuthService },
    /* Set default locale data for the application.  */
    { provide: LOCALE_ID, useValue: 'hu' },
    /** By default the currency uses USD regardless of locale, we set it to use HUF  */
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'HUF' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshRolesInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AcceptLanguageInterceptor,
      multi: true,
    },
  ],
  bootstrap: [RootViewComponent],
})
export class RootModule {}
