/* eslint-disable-next-line no-shadow */
export enum ContractStatus {
  /**
   * When a contract is generated from an offer it has the draft status.
   * A contract in this status is freely editable without restrictions.
   */
  Draft = 'DRAFT',

  /**
   * When the user is ready preparing a contract and all data is added the
   * contract is marked as pending review.
   *
   * This status can be marked only if the contract passes full validation.
   * A contract in this state cannot be edited by the user.
   */
  PendingReview = 'PENDING',

  /**
   * A reviewed contract is a finalized version by Alteo. This status is set
   * when Alteo reviewed everything, found it fine and ready to proceed.
   *
   * In this status no-one can edit a contract besides the status change and
   * attaching files.
   */
  Reviewed = 'REVIEWED',

  /**
   * If all the required attachments are uploaded (not checked programmatically)
   * then Alteo can mark a contract accepted.
   *
   * ??? IMPORTANT: A single contract cannot be marked as accepted. All the
   * ??? contracts inside a contract group must be accepted together.
   */
  Accepted = 'ACCEPTED',

  /**
   * A contract becomes active automatically when the start date has passed.
   */
  Active = 'ACTIVE',

  /**
   * A contract can be marked as rejected anytime before becoming active.
   */
  Rejected = 'REJECTED',

  /**
   * A contract expire automatically once it's validity date has passed.
   */
  Expired = 'EXPIRED',
}
