import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class PaginatorLocalizationService extends MatPaginatorIntl {
  /**
   * We remove the default english "Next Page" and "Previous Page" tooltips.
   */
  public nextPageLabel = '';
  public previousPageLabel = '';

  /**
   * Override the range rendering to contain only numbers so no translation is required.
   */
  /* eslint-disable-next-line space-before-function-paren */
  public getRangeLabel = function (currentPage, pageSize, totalItemCount) {
    if (totalItemCount === 0 || pageSize === 0) {
      return `0 / ${totalItemCount}`;
    }

    totalItemCount = Math.max(totalItemCount, 0);
    const startIndex = currentPage * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < totalItemCount ? Math.min(startIndex + pageSize, totalItemCount) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} / ${totalItemCount}`;
  };
}
