import { Component, OnDestroy, OnInit } from '@angular/core';
import { NetworkService } from '@spreadmonitor/network';
import { Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from './auth/services';
import { LocaleService } from './shared/services';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class RootViewComponent implements OnInit, OnDestroy {
  private readonly onDestroy = new Subject();

  /** We need to inject locale service so selected language is used instead of default one. */
  constructor(
    private network: NetworkService,
    private auth: AuthService,
    private localeService: LocaleService
  ) {}

  ngOnInit() {
    this.network.networkError
      .pipe(
        takeUntil(this.onDestroy),
        /**
         * We skip the redirect if we tried to sign in, because in that case
         * we should stay on the same page to keep the error messages.
         */
        filter(errorResponse => !errorResponse.url.endsWith('/password-sign-in')),
        map(errorResponse => errorResponse.status),
        filter(responseStatus => responseStatus === 401),
        tap(() => this.auth.logout(true))
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.onDestroy.next(true);
    this.onDestroy.complete();
  }
}
