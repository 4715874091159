import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { LocaleService } from './shared/services';

@Injectable({
  providedIn: 'root',
})
export class AcceptLanguageInterceptor implements HttpInterceptor {
  private injectedLocaleService: LocaleService;

  constructor(private readonly injector: Injector) {
    /** Need to wait for the translate module to load to avoid circular dependency. */
    setTimeout(() => {
      this.injectedLocaleService = injector.get(LocaleService);
    }, 0);
  }

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    /**
     * Until we have a working service injected we just forward the requests (which already contains the Accept-Language generate by the browser).
     */
    if (!this.injectedLocaleService) {
      return next.handle(req);
    }

    const modifiedReq = req.clone({
      headers: req.headers.set('Accept-Language', this.injectedLocaleService.locale),
    });

    return next.handle(modifiedReq);
  }
}
