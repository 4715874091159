/**
 * The area a contact user is responsible for.
 */
/* eslint-disable-next-line no-shadow */
export enum ContractUserType {
  /**
   * Technical contact users are contacted to solve operational issues, such as
   * lost telemetry connection.
   */
  Technical = 'technical',

  /**
   * Financial contact users are contacted with billing related problems.
   */
  Financial = 'financial',

  /**
   * Unknown type.
   * This type should not be encountered, it used as a fallback value.
   */
  Unknown = 'unknown',
}
